import { getBalance } from '@api/dashboard'
import { CountryMap } from '@utils/data/countries'

const walletCurrencySwitch = {
  methods: {
    switchWallet() {
      if (this.walletBalanceCard.isLoading) return
      this.walletBalanceCard.isLoading = true
      this.walletCountry = this.walletCountry === 'CA' ? 'US' : 'CA'
      getBalance(CountryMap[this.walletCountry]).then((res) => {
        this.walletBalanceCard.walletBalance = res.data.walletBalance
        this.walletBalanceCard.walletCurrency = res.data.walletCurrency
        this.walletBalanceCard.processingDepositTotal = res.data.processingDepositTotal
        this.$refs.walletTransactionsComponent && this.$refs.walletTransactionsComponent.getTransactions(true)
        this.walletBalanceCard.isLoading = false
      })
    },
  },
}

export default walletCurrencySwitch
