<template>
  <Teleport to="#desktop-header-left">
    <div>
      <div class="welcome-message has-text-weight-semibold mb-1">Welcome {{user.firstTimeOnMobileUi ? 'aboard' : 'back'}}, {{user.firstName}}!</div>
      <div class="membership-message" v-if="user.membershipType !== 'Unpaid'">{{user.membershipType}} since {{membershipChargedAt}}</div>
    </div>
  </Teleport>
</template>
<script>
import { DateTime } from 'luxon'
import Teleport from 'vue2-teleport'

export default {
  components: {
    Teleport,
  },
  computed: {
    user() {
      const savedUserInfo = localStorage.getItem('investor')
      if (!savedUserInfo) return {}
      const { membershipType, membershipDate, firstTimeOnMobileUi, firstName } = JSON.parse(savedUserInfo)
      return {
        membershipType,
        membershipDate,
        firstTimeOnMobileUi,
        firstName,
      }
    },
    membershipChargedAt() {
      if (!this.user.membershipDate) return ''
      return DateTime.fromISO(this.user.membershipDate).toFormat('MMMM dd, yyyy')
    },
  },
}
</script>
<style lang="scss" scoped>
.welcome-message {
  font-size: 18PX;
}
.membership-message {
  font-size: 16PX;
}
</style>
