<template>
  <div class="wallet-landing-page">
    <WelcomeMessage/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo :showReferral="true" referralIconBackgroundColor="#fff"></MenuAndLogo>
    </div>
    <div class="wallet-card-wrap">
      <WalletCard
        :data="walletBalanceCard"
        @switchWallet="switchWallet"
        ref="walletCardComponent">
      </WalletCard>
    </div>
    <div class="add-or-withdraw is-flex is-justify-content-space-between">
      <div class="add-funds is-flex is-align-items-center is-clickable" @click="toAddFundsPage()">
        <div class="icon-wrap is-flex is-justify-content-center is-align-items-center">
          <img src="https://addy-public.s3.us-west-2.amazonaws.com/transaction-type-deposit.svg" alt="add-funds">
        </div>
        <div class="has-text-blue has-text-weight-semibold has-text-centered button-text">Add funds</div>
      </div>
      <div class="withdraw is-flex is-align-items-center is-clickable" @click="toWithdrawPage">
        <div class="icon-wrap is-flex is-justify-content-center is-align-items-center">
          <img src="https://addy-public.s3.us-west-2.amazonaws.com/transaction-type-withdrawal.svg" alt="withdraw">
        </div>
        <div class="has-text-blue has-text-weight-semibold has-text-centered button-text">Withdraw funds</div>
      </div>
    </div>
    <div class="linked-account-wrap">
      <div class="section-title is-flex is-justify-content-space-between is-align-items-center">
        <div>Your linked accounts</div>
        <div class="add-account is-flex is-align-items-center is-clickable" @click="addBankAccount">
          <span class="has-text-primary has-text-weight-semibold">ADD NEW</span>
          <span class="has-text-primary has-text-weight-semibold plus-sign">+</span>
        </div>
      </div>
      <LinkedAccount @syncNumberOfAccount="syncNumberOfAccount"></LinkedAccount>
    </div>
    <div class="transactions-wrap">
      <div class="section-title">Your transactions</div>
      <Transactions
        @updateWalletCard="getData"
        :walletCountry="walletCountry"
        ref="walletTransactionsComponent"
      >
      </Transactions>
    </div>
    <modal
      text="You can only have 1 bank account connected"
      :showModal="showOneAccountModal"
      @closeModal="showOneAccountModal = false"
    ></modal>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import WalletCard from '@views/dashboard/WalletCard.vue'
import LinkedAccount from './LinkedAccount.vue'
import Transactions from './Transactions.vue'
import modal from '@components/modal/confirm-modal'
import axios from '@lib/axios/middleware'
import { CountryMap } from '@utils/data/countries'
import { getInvestorData } from '@api/signup'
import { getBalance } from '@api/dashboard'
// import { investorInAvailableRegion } from '@utils/common-methods/common'
import walletRoutes from '@utils/mixins/wallet-routes'
import walletCurrencySwitch from '@utils/mixins/wallet-currency-switch-mixin'

export default {
  mixins: [walletRoutes, walletCurrencySwitch],
  data() {
    return {
      walletCountry: 'CA',
      walletBalanceCard: {
        walletBalance: 0,
        addyHandle: '',
        firstName: '',
        lastName: '',
        processingDepositTotal: 0,
        inAvailableRegion: true,
        isLoading: false,
      },
      tooltipTimer: null,
      hasAccountConnected: false,
      showOneAccountModal: false,
    }
  },
  components: {
    MenuAndLogo,
    WelcomeMessage,
    WalletCard,
    LinkedAccount,
    Transactions,
    modal,
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      axios.all([
        getInvestorData(),
        getBalance(CountryMap[this.walletCountry]),
      ]).then((data) => {
        const { addyHandle, firstName, lastName } = data[0]
        this.walletBalanceCard.addyHandle = addyHandle
        this.walletBalanceCard.firstName = firstName
        this.walletBalanceCard.lastName = lastName
        // { administrativeAreaLevel1, country } in data[0]
        // this.inAvailableRegion = investorInAvailableRegion(country, administrativeAreaLevel1)
        if (data[1].success) {
          const { walletBalance, processingDepositTotal, walletCurrency } = data[1].data
          this.walletBalanceCard.walletBalance = walletBalance
          this.walletBalanceCard.processingDepositTotal = processingDepositTotal
          this.walletBalanceCard.walletCurrency = walletCurrency
        }
      })
    },
    toWithdrawPage() {
      this.$router.push('/wallet/withdraw')
    },
    addBankAccount() {
      if (this.hasAccountConnected) this.showOneAccountModal = true
      else this.toLinkBankPage()
    },
    syncNumberOfAccount(bool) {
      this.hasAccountConnected = bool
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.wallet-landing-page {
  padding-top: 92px;
  .fixed-background {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #F0EEF8;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  .wallet-card-wrap {
    .add-fund {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      right: 9px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.5);
      .dots {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        &.middle {
          margin: 0 3px;
        }
      }
      .add-funds-or-withdraw-tooltip {
        position: absolute;
        top: 40px;
        right: 0;
        width: 100px;
        height: 60px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        font-size: 14px;
        line-height: 29px;
        padding: 0 8px;
        .add-funds {
          border-bottom: 1px solid rgb(255, 255, 255, 0.25);
        }
        .triangle {
          width: 0;
          height: 0;
          border: 4px solid transparent;
          border-bottom-color: rgba(255, 255, 255, 0.5);
          border-bottom-width: 6px;
          position: absolute;
          right: 12px;
          top: -10px;
        }
      }
    }
  }
  .add-or-withdraw {
    padding: 0 25px 26px;
    margin-top: -10px;
    .add-funds,
    .withdraw {
      width: calc(50% - 6px);
      height: 48px;
      border: 1px solid #F0EEF8;
      border-radius: 8px;
      padding-left: 8px;
      .icon-wrap {
        width: 32px;
        height: 32px;
        background-color: #F0EEF8;
        border-radius: 4px;
      }
      .button-text {
        flex: 1;
        font-size: 14px;
      }
    }
  }
  .section-title {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
  .linked-account-wrap {
    padding: 4px 25px 16px;
    .add-account {
      font-size: 12px;
      .plus-sign {
        font-size: 20px;
        margin-left: 4px;
        padding-bottom: 2px;
      }
    }
  }
  .transactions-wrap {
    padding: 0 25px 60px;
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .wallet-landing-page {
    padding-top: 19px;
    min-height: calc(#{$min-height-mobile-container} + 2px);
    max-width: $mobile-wrapper-width;
  }
}
</style>
